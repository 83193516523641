body {
  margin: 0;
  font-family: 'campton-book', 'campton-medium', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
  font-family: 'campton-book';
  src: url('fonts/campton-book.otf') format('opentype');
}

@font-face {
  font-family: 'campton-medium';
  src: url('fonts/campton-medium.otf') format('opentype');
}
